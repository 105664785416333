import React from 'react'
import { Grid, Box } from '@mui/material';
import bgimage from '../images/fitrah.png'
import bgimageSm from '../images/fitrah-sm.png'
import styled from '@emotion/styled';

const HeroSection = styled(Box)`
  padding: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  height: 100vh;
  background-image: url(${bgimage});
  background-size: 100% 90%;
  background-attachment: fixed;
  background-position: center bottom;
  background-repeat: no-repeat;
  @media (max-width: 600px) {
    background-image: url(${bgimageSm});
    background-size: 100% 92.5%;
  }
`;

function Hero({ width, children }) {
    return (
        <HeroSection>
            <Grid sx={{
                bgcolor: 'white',
                opacity: "0.75",
                width: { xs: "90%", sm: width },
                padding: '35px',
                borderRadius: '50px'
            }}>
                {children}
            </Grid>
        </HeroSection>
    )
}

export default Hero