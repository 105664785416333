import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import TopBar from './components/TopBar';
import LandingPage from './routes/LandingPage';
import JoinPage from './routes/JoinPage';
import CoursesPage from './routes/CoursesPage';
import CourseDetailPage from './routes/CourseDetailPage';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AboutPage from './routes/AboutPage';
import Footer from './components/Footer';
import coursesData from './data/courses.json';
import ww from './images/ww.png'
import pw from './images/pw.png'
import sw from './images/sw.png'
import spw from './images/spw.png'

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffffff'
    },
  }
});

function App() {
  const images = { "ww": ww, "pw": pw, "sw": sw, "spw": spw };

  const courses = coursesData.map(course => ({
    ...course,
    image: images[course.image]
  }));

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <TopBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/join" element={<JoinPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/courses" element={<CoursesPage courses={courses} />} />
          <Route path="/courses/:key" element={<CourseDetailPage courses={courses} />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;