import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import logo from '../images//logo.jpeg'
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';

const Navbar = styled(AppBar)`
margin-bottom: 16px;
background-color: white
`;

const NavButton = styled(Button)`
  margin-right: 16px;
  font-weight: bold;
`;

const ListItemTextStyled = styled(ListItemText)`
  color: black;
  text-align: center;
  border: 1px solid black
`;

const TopBar = () => {
  let navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Navbar position="fixed">
      <Toolbar>
        <Box
          component="img"
          sx={{
            height: { xs: 70, sm: 100 },
            cursor: 'pointer'
          }}
          onClick={() => navigate('/')}
          alt="logo"
          src={logo}
        />
        {isMobile ? (
          <>
            <IconButton
              color="primary"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ marginLeft: 'auto', marginRight: "16px" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              sx={{
                width: 200,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: 200,
                  boxSizing: 'border-box',
                },
              }}
              open={isDrawerOpen}
              onClose={handleDrawerToggle}
            >
              <List sx={{ marginTop: "20px" }}>
                <ListItem component={Link} to="/" onClick={handleDrawerToggle}>
                  <ListItemTextStyled primary="Home" />
                </ListItem>
                <ListItem component={Link} to="/join" onClick={handleDrawerToggle}>
                  <ListItemTextStyled primary="Join" />
                </ListItem>
                <ListItem component={Link} to="/about" onClick={handleDrawerToggle}>
                  <ListItemTextStyled primary="About us" />
                </ListItem>
                <ListItem component={Link} to="/courses" onClick={handleDrawerToggle}>
                  <ListItemTextStyled primary="Courses" />
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : (
          <Box sx={{ marginLeft: 'auto' }}>
            <NavButton component={Link} color="primary" to="/">Home</NavButton>
            <NavButton component={Link} color="primary" to="/join">Join</NavButton>
            <NavButton component={Link} color="primary" to="/about">About us</NavButton>
            <NavButton component={Link} color="primary" to="/courses">Courses</NavButton>
          </Box>
        )}
      </Toolbar>
    </Navbar>
  );
};

export default TopBar;