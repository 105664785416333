import React, { useRef } from 'react';
import { Typography, Box, Button, Grid, Card, CardMedia, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Hero from '../components/Hero';

const Section = styled(Box)`
  padding: 80px 0;
  @media (max-width: 600px) {
    padding: 60px 0;
  }
`;

const CoursesPage = ({ courses }) => {
  const formRef = useRef(null)
  const executeScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <>
      <Hero width={"60%"}>
        <Typography sx={{ typography: { md: 'h5', xs: 'subtitle1' } }}>Getting equipped with knowledge</Typography>
        <Typography sx={{ typography: { md: 'body1', xs: 'body2' } }}>Join the training for the very cause. Select from courses below.</Typography>
        <Button onClick={() => executeScroll()} type="submit" variant="contained" color="secondary">Enroll Now</Button>
      </Hero>
      <Section ref={formRef}>
        <Grid container spacing={3} justifyContent="space-around">
          {courses.map((course, index) => (
            <Grid item key={index}>
              <CourseCard name={course.name} image={course.image} courseKey={course.key} />
            </Grid>
          ))}
        </Grid>
      </Section>
    </>
  )
}

const CourseCard = ({ name, image, courseKey }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 345, cursor: 'pointer' }} onClick={() => navigate(`/courses/${courseKey}`)}>
      <CardMedia component="img" image={image} alt={name} sx={{ aspectRatio: '4/3' }} />
      <CardContent>
        <Typography textAlign="center" variant="h6">{name}</Typography>
      </CardContent>
    </Card>
  );
}

export default CoursesPage