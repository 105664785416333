import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Typography, Container, Box, Grid, TextField, Button, Card, CardMedia, useMediaQuery } from '@mui/material';
import axios from 'axios';

const CourseDetailPage = ({ courses }) => {
  const { key } = useParams();
  const course = courses.find(course => course.key === key);
  const isShortScreen = useMediaQuery('(max-width:600px)');

  // State for form fields
  const [formData, setFormData] = useState({ name: '', phone: '', email: '' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  

  if (!course) {
    return (
      <Container sx={{ marginTop: "200px", marginBottom: "100px" }}>
        <Typography variant="h4" textAlign="center" mt={5}>Course Not Found</Typography>
      </Container>
    );
  }

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      const response = await axios.post("https://fr-mail-484064816594.asia-southeast1.run.app", {...formData, course: course.name});
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Failed to enroll. Please try again.");
    } finally {
      setLoading(false);
    }
  };  

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', marginTop: isShortScreen ? '100px' : '140px', marginBottom: "40px" }}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Card sx={{ maxWidth: 450 }}>
            <CardMedia component="img" image={course.image} alt={course.title} sx={{ borderRadius: 2 }} />
          </Card>
          <Box maxWidth="450px" component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Your Phone"
              variant="outlined"
              margin="normal"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Your Email"
              variant="outlined"
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={loading}>
              {loading ? "Enrolling..." : "Enroll Now"}
            </Button>
            {message && (
              <Typography variant="body2" color={message.includes("Failed") ? "error" : "success"} sx={{ mt: 2 }}>
                {message}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="600" gutterBottom>{course.title}</Typography>
          <Typography variant="body1" paragraph>
            <ReactMarkdown>{course.description}</ReactMarkdown>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CourseDetailPage